var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v(" ຈຳນວນຕຸກນ້ຳ "),_c('span',{staticClass:"primary-color"},[_vm._v("("+_vm._s(_vm.countBottleExpire)+")")])])]),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":"Search","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchDataBottleExpire()}},model:{value:(_vm.searchExpire),callback:function ($$v) {_vm.searchExpire=$$v},expression:"searchExpire"}})],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading},on:{"click":function($event){return _vm.exportData()}}},[_vm._v("Export ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersBottleExpire,"items":_vm.expireBottle,"disable-pagination":true,"hide-default-footer":"","loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait","search":_vm.searchExpire},scopedSlots:_vm._u([{key:"item.round",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getRoundColor(item.round)}},[_vm._v(_vm._s(item.round))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("MM:HH DD-MM-YYYY"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status)}},[_vm._v(_vm._s(_vm.getStatus(item.status)))])]}},{key:"item.status_type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusTypeColor(item.status_type)}},[_vm._v(" "+_vm._s(_vm.getStatusType(item.status_type))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.OpenModalEdit(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.viewProduct(item.code_id)}}},[_vm._v("mdi-map-marker-circle")])]}}])}),_c('br'),[(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchDataBottleExpire()}}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }