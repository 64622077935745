var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-2"},[_c('v-col',[_c('h2',[_vm._v(" ຈຳນວນລູກຄ້າທີ່ຊື້ນ້ຳ"),_c('span',{staticClass:"primary-color"},[_vm._v(" ("+_vm._s(_vm.countCustomer)+")")])])]),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":"ຄົ້ນຫາດ້ວຍ ຊື່, ລະຫັດລູກຄ້າ","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.Search()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading},on:{"click":function($event){return _vm.exportData()}}},[_vm._v("Export ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.customers,"disable-pagination":true,"hide-default-footer":"","loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.surname))])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.villageName)+", "+_vm._s(item.districtName))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"success"}},[_vm._v(_vm._s(item.products.length))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.phone))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("HH:MM DD-MM-YYYY"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){return _vm.viewProduct(item.id)}}},[_vm._v("mdi-eye")]),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.exportCustomerBottle(item.id)}}},[_vm._v("Export ")])]}}])}),_c('br'),(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchDataCustomer()}}}):_vm._e(),_vm._l((_vm.listProductDetail),function(items,index){return _c('div',{key:index},[_c('v-dialog',{attrs:{"persistent":"","width":"800px","max-with":"1200px","loading":""},model:{value:(_vm.viewCustomer),callback:function ($$v) {_vm.viewCustomer=$$v},expression:"viewCustomer"}},[[_c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_c('h4',[_vm._v("ຕຸກນ້ຳຢູ່ນຳລູກຄ້າ")])]),_c('v-card-text',[_c('v-card-text',_vm._l((_vm.listProductDetail),function(items,index){return _c('v-simple-table',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("ບາໂຄດ")]),_c('th',{staticClass:"text-left"},[_vm._v("ວັນທີນຳເຂົ້າ")]),_c('th',{staticClass:"text-left"},[_vm._v("ຜູ້ຮັບຜິດຊອບ")])])]),_c('tbody',_vm._l((items.products),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.barcode))]),_c('td',[_vm._v(_vm._s(item.import_date))]),_c('td',[_vm._v(_vm._s(item.created_by))])])}),0)]},proxy:true}],null,true)})}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1 depressed","text":""},on:{"click":function($event){_vm.viewCustomer = false}}},[_vm._v(" Close ")])],1)],1)],1)]],2)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }