<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#tab-1">ຕຸກນ້ຳໃນໂຮງງານ</v-tab>
        <v-tab href="#tab-9"><span>ຕຸກນ້ຳສາງໂຮງງານ</span></v-tab>
<!--        <v-tab href="#tab-2">ຕຸກເປົ່າໃນໂຮງງານ</v-tab>-->
<!--        <v-tab href="#tab-3">ຕຸກມີນ້ຳໃນໂຮງງານ</v-tab>-->
<!--        <v-tab href="#tab-4">ຕຸກນ້ຳຢູ່ໂຮງງານ</v-tab>-->
        <v-tab href="#tab-5">ຕຸກນ້ຳໃນລົດຂົນສົ່ງ</v-tab>
        <v-tab href="#tab-6">ຕຸກນ້ຳຢູ່ສາຂາ</v-tab>
        <v-tab href="#tab-7">ຕຸກນ້ຳຢູ່ນຳລູກຄ້າ</v-tab>
<!--        <v-tab href="#tab-8"><span>ຕຸກນ້ຳປິດການໃຊ້ງານ</span></v-tab>-->
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>
              <Allproducts />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
              <AllproductsEmpty />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-3">
          <v-card flat>
            <v-card-text>
              <AllProductsHaveWater />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-4">
          <v-card flat>
            <v-card-text>
              <WaterBottleFactory/>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-5">
          <v-card flat>
            <v-card-text>
              <WaterBottleDriver />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-6">
          <v-card flat>
            <v-card-text>
              <WaterBottleBranch />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-7">
          <v-card flat>
            <v-card-text> <WaterBottleCustomer /></v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-8">
          <v-card flat>
            <v-card-text>
              <AllproductsExpire />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-9">
          <v-card flat>
            <v-card-text>
              <DistributionCenter />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Allproducts from "@views/Bottle/WaterBottleAll";
import AllProductsHaveWater from "@views/Bottle/WaterBottleAllHaveWater";
import AllproductsEmpty from "@views/Bottle/WaterBottleAllEmpty";
import AllproductsExpire from "@views/Bottle/WaterBottleAllExpire";
import WaterBottleDriver from "@views/Bottle/truck/Truck_Water";
import WaterBottleFactory from "@views/Bottle/WaterBottle_Factory";
import WaterBottleBranch from "@views/Bottle/branch/Branch_Water";
import WaterBottleCustomer from "@views/Bottle/customer/Customer_Water";
import DistributionCenter from "@views/Bottle/distributionCenter/index";
import manage_water_bottle from "@/mixins/water/manage_water_bottle";

export default {
  mixins: [manage_water_bottle],
  components: {
    Allproducts,
    AllProductsHaveWater,
    AllproductsEmpty,
    WaterBottleDriver,
    WaterBottleFactory,
    WaterBottleBranch,
    WaterBottleCustomer,
    AllproductsExpire,
    DistributionCenter,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.query.tab == "all-bottle") {
      this.tab = "tab-1";
    // } else if (this.$route.query.tab == "empty-bottle") {
    //   this.tab = "tab-2";
    // } else if (this.$route.query.tab == "hav-water-bottle") {
    //   this.tab = "tab-3";
    // } else if (this.$route.query.tab == "factory-bottle") {
    //   this.tab = "tab-4";
    }else if (this.$route.query.tab == "truck-bottle") {
        this.tab = "tab-5";
    } else if (this.$route.query.tab == "branch-bottle") {
      this.tab = "tab-6";
    } else if (this.$route.query.tab == "customer-bottle") {
      this.tab = "tab-7";
    } else if (this.$route.query.tab == "expire-bottle") {
      this.tab = "tab-8";
    }else if (this.$route.query.tab == "distribution-bottle") {
      this.tab = "tab-9";
    }
  },
  watch: {
    tab: function (value) {
      if (value == "tab-1") {
        this.$router
          .push({ name: "Water", query: { tab: "all-bottle" } })
          .catch(() => {});
      // } else if (value == "tab-2") {
      //   this.$router
      //     .push({ name: "Water", query: { tab: "empty-bottle" } })
      //     .catch(() => {});
      // } else if (value == "tab-3") {
      //   this.$router
      //     .push({ name: "Water", query: { tab: "have-water-bottle" } })
      //     .catch(() => {});
      // }
      // else if (value == "tab-4") {
      //   this.$router
      //       .push({ name: "Water", query: { tab: "factory-bottle" } })
      //       .catch(() => {});
      }else if (value == "tab-5") {
        this.$router
          .push({ name: "Water", query: { tab: "truck-bottle" } })
          .catch(() => {});
      } else if (value == "tab-6") {
        this.$router
          .push({ name: "Water", query: { tab: "branch-bottle" } })
          .catch(() => {});
      } else if (value == "tab-7") {
        this.$router
          .push({ name: "Water", query: { tab: "customer-bottle" } })
          .catch(() => {});
      } else if (value == "tab-8") {
        this.$router
          .push({ name: "Water", query: { tab: "expire-bottle" } })
          .catch(() => {});
      }
      else if (value == "tab-9") {
        this.$router
            .push({ name: "Water", query: { tab: "distribution-bottle" } })
            .catch(() => {});
      }
    },
  },
};
</script>

<style></style>
