<template>
  <div>
    <v-row>
      <v-col cols>
        <v-autocomplete
            v-model="selectedFactory"
            :items="factories"
            label="ເລືອກໂຮງງານ"
            item-text="name"
            item-value="id"
            dense
            outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols>
        <v-autocomplete
            v-model="selectedStatus"
            :items="listStatuses"
            label="ສະຖານະຕຸກນ້ຳ"
            item-text="name"
            item-value="type"
            dense
            outlined
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          label="ຄົ້ນຫາ ດ້ວຍລະຫັດຕຸກນ້ຳ"
          type="text"
          v-model="search"
          @keyup.enter="getData()"
        >
        </v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>
    <v-row>
        <v-col>
          <h2>
            ຕຸກນ້ຳລວມ <span class="primary-color">({{ countTotal }})</span>
          </h2>
        </v-col> <v-col>
          <h2>
            ຕຸກນ້ຳມີນ້ຳ <span class="primary-color">({{ countUsing }})</span>
          </h2>
        </v-col> <v-col>
          <h2>
            ຕຸກເປົ່າ <span class="primary-color">({{ countEmpty }})</span>
          </h2>
        </v-col> <v-col>
          <h2>
            ຕຸກນ້ຳປິດໃຊ້ງານ <span class="primary-color">({{ countClose }})</span>
          </h2>
        </v-col> <v-col>
          <h2>
            ຕຸກນ້ຳໝົດອາຍຸ <span class="primary-color">({{ countExpire }})</span>
          </h2>
        </v-col>
    </v-row>

    <!-- Add button -->

    <v-row justify="end">
      <template>
        <div>
          <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="products"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
      :search="search"
    >
      <template v-slot:item.round="{ item }">
        <v-chip :color="getRoundColor(item.round)">{{ item.round }}</v-chip>
        <!-- <v-chip v-if="item.status === 'using'" class="success">{{item.status}}</v-chip>
     <v-chip v-else-if="item.status === 'empty'" class="danger">{{item.status}}</v-chip> -->
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ moment(item.created_at).format("MM:HH DD-MM-YYYY") }}
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)">{{
          getStatus(item.status)
        }}</v-chip>
        <!-- <v-chip v-if="item.status === 'using'" class="success">{{item.status}}</v-chip>
     <v-chip v-else-if="item.status === 'empty'" class="danger">{{item.status}}</v-chip> -->
      </template>
      <template v-slot:item.status_type="{ item }">
        <v-chip :color="getStatusTypeColor(item.status_type)">
          {{ getStatusType(item.status_type) }}
        </v-chip>
      </template>
      <!-- <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="viewProduct(item.code_id)"
          >mdi-map-marker-circle</v-icon
        >
      </template> -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <!-- <v-icon small class="mr-2" @click="viewProduct(item.code_id)"
          >mdi-map-marker-circle</v-icon
        > -->
        <v-icon small @click="closeBottle(item.id)">mdi-close</v-icon>
      </template>
    </v-data-table>
    <br />
    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="getData()"
      ></Pagination>
    </template>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            ເພີ່ມຕຸກນ້ຳ
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols>
                    <v-select
                        dense
                        required
                        v-model="selectedType"
                        :items="listType"
                        item-text="name"
                        item-value="id"
                        label="ປະເພດ *"
                        :rules="ruleType"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຈຳນວນຕຸກ *"
                        required
                        v-model="product"
                        :rules="nameRules"
                        @keyup.enter="AddItem"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.quantity }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="AddItem()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
          <!-- @keyup.native="AddItem" -->
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update Barcode</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Barcode*"
                      required
                      v-model="product_edit.code_id"
                      :rules="nameRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.barcode }}
                    </p>
                    <p class="errors">
                      {{ usingError }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="UpdateItem()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>
    <!--Delete Modal-->
    <!-- <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="loading"
            @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete> -->

    <ModalView>
      <template @close="close">
        <v-card :loading="loading" :disabled="loading">
          <v-card-title>
            <h4>ຕິດຕາມກຕຸກນ້ຳ</h4>
          </v-card-title>
          <v-card-text>
            <v-timeline v-if="tracking.length > 0" dense fill-dot>
              <v-timeline-item
                v-for="(track, i) in tracking"
                v-bind:value="track.id"
                :key="i"
                class="mb-4"
                color="#00c1d2"
                icon="mdi-arrow-up-circle"
                icon-color="primary-color lighten-2"
                large
              >
                <v-row justify="space-between">
                  <v-col cols="9">
                    <h2 class="font-weight-bold">
                      {{ getTrackStatusType(track.status_type) }}
                      {{ getTrackStoreType(track.store_type) }}
                    </h2>
                    <span v-if="track.driver"
                      >ທະບຽນລົດ: {{ track.driver.license_plate }}</span
                    >
                    <span v-if="track.branch">
                      ສາຂາ: {{ track.branch.name }}</span
                    >
                    <span v-if="track.customer">
                      ລູກຄ້າ: {{ track.customer.name }} / ໂທ:
                      {{ track.customer.phone }}</span
                    >
                    <p class="green--text">{{ track.status }}.</p>
                  </v-col>
                  <v-col class="text-left" cols="3">
                    <span>
                      {{
                        moment(track.created_at).format("HH:MM / DD-MM-YYYY")
                      }}</span
                    >
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
            <h2 v-else class="text-center">"ຕຸກນ້ຳຍັງຢູ່ໃນໂຮງງານ"</h2>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1 depressed" text @click="closeModal()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>
  </div>
</template>

<script>
import manage_water_bottle from "../../mixins/water/manage_water_bottle";
import Pagination from "@/plugins/pagination/pagination";
export default {
  mixins: [manage_water_bottle],
  components: {
    Pagination,
  },
  metaInfo() {
    return {
      title: `ຂໍ້ມູນຕຸກນ້ຳທັງໝົດ`,
    }
  },
  created() {
    // this.getData();
  },
};
</script>
<style>
.primary-color {
  color: #00c1d2;
}
</style>
