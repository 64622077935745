<template>
  <div>
    <v-row>
      <v-col cols>
        <v-autocomplete
            v-model="selectedFactory"
            :items="factories"
            label="ເລືອກໂຮງງານ"
            item-text="name"
            item-value="id"
            dense
            outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols>
        <v-autocomplete
            v-model="selectedDistribution"
            :items="selectedDistributions"
            label="ສູນແຈກຢາຍນ້ຳ"
            item-text="name"
            item-value="id"
            dense
            outlined
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field
            outlined
            dense
            clearable
            label="ຄົ້ນຫາ ດ້ວຍລະຫັດຕຸກນ້ຳ"
            type="text"
            v-model="search"
            @keyup.enter="fetchDistribution()"
        >
        </v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>
    <v-row class="mb-1">
      <v-col>
        <h2>
          ລວມຕຸກມີນ້ຳ <span class="primary-color">({{ countTotal }})</span>
        </h2>
      </v-col>
      <v-col>
        <h2>
          ລວມຕຸກເປົ່າ <span class="primary-color">({{ countEmpty }})</span>
        </h2>
      </v-col>
    </v-row>

    <!-- Add button -->

    <v-data-table
        :headers="headers"
        :items="products"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
        :search="search"
    >
      <template v-slot:item.updated_at="{ item }">
        {{ moment(item.created_at).format("MM:HH DD-MM-YYYY") }}
        <!-- {{item.created_at}} -->
      </template>
      <!-- <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="viewProduct(item.code_id)"
          >mdi-map-marker-circle</v-icon
        >
      </template> -->
      <!--      <template v-slot:item.actions="{ item }">-->
      <!--        <v-icon small class="mr-2" @click="viewProduct(item.code_id)"-->
      <!--          >mdi-map-marker-circle</v-icon-->
      <!--        >-->
      <!--      </template>-->
    </v-data-table>
    <br/>
    <!--    <template>-->
    <!--      <Pagination-->
    <!--        v-if="pagination.last_page > 1"-->
    <!--        :pagination="pagination"-->
    <!--        :offset="offset"-->
    <!--        @paginate="fetchDistribution()"-->
    <!--      ></Pagination>-->
    <!--    </template>-->
  </div>
</template>

<script>
import manage_water_bottle from "../../../mixins/sortingCenter/report_bottle";
// import Pagination from "../../../plugins/pagination/pagination";
export default {
  mixins: [manage_water_bottle],
  components: {
    // Pagination,
  },
  metaInfo() {
    return {
      title: `ຂໍ້ມູນຕຸກນ້ຳທັງໝົດ`,
    }
  },
  created() {
    // this.getData();
  },
};
</script>
<style>
.primary-color {
  color: #00c1d2;
}
</style>
